
  import axios from 'axios';
  import store from './store'

  const $axios = axios.create({
      baseURL: process.env.VUE_APP_BACKEND_URL,
      headers: {
        'Content-Type': 'application/json'
      }
  });

  $axios.interceptors.request.use (
      function (config) {
        const token = store.getters['auth/token'];
        if (token) config.headers.Authorization = `Bearer ${token}`;
        return config;
      },
      function (error) {
        return Promise.reject (error);
      }
  );
    
  export default $axios;
